<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Info Cards"
                >
                    <template v-slot:description>
                        import { HbInfoCard } from 'hummingbird-aviary';
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Info Card Examples + Code" class="mt-4 mb-6">
            <div class="ma-6">

                <v-row class="ma-0 pa-0">
                    <v-col cols="12" sm="6" class="ma-0 pa-0" :class="{'pr-3' : $vuetify.breakpoint.smAndUp, 'mb-11' : $vuetify.breakpoint.xs}">
                        <HbInfoCard
                           type="storage"
                           title="309"
                           status="Available"
                           category-one="5x5"
                           category-two="Storage"
                           property-info="SO657 - Pomona - 123 Ocean Avenue"
                           amenities="Moving Options: 18 Wheeler Aisles, Access: 24-access, Payment Options: ACH, Security: Electronic Gate Access, Insurance: Homeowners or renters insurace accepted, Vehicle Storage: Must be running"
                        />
                        <div class="mt-4 d-flex justify-center">Unit Info Card</div>
                    </v-col>
                    <v-col cols="12" sm="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.smAndUp}">
                        <HbInfoCard
                           type="contact"
                           title="John Smith"
                           status="Gate Lockout"
                           phone="+1 (310) 234-2354"
                           email="johnsmith@somewebsite.coms"
                           contact-address="100 Main Street, Los Angeles, CA 90039"
                           access-code="123456"
                        />
                        <div class="mt-4 d-flex justify-center">Contact Info Card</div>
                    </v-col>
                </v-row>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbInfoCard
   type="storage"
   title="309"
   status="Available"
   category-one="5x5"
   category-two="Storage"
   property-info="SO657 - Pomona - 123 Ocean Avenue"
   amenities="Moving Options: 18 Wheeler Aisles, Access: 24-access, Payment Options: ACH, Security: Electronic Gate Access, Insurance: Homeowners or renters insurace accepted, Vehicle Storage: Must be running"
/>

&lt;HbInfoCard
   type="contact"
   title="John Smith"
   status="Gate Lockout"
   phone="+1 (310) 234-2354"
   email="johnsmith@somewebsite.coms"
   contact-address="100 Main Street, Los Angeles, CA 90039"
   access-code="123456"
/>
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>
        
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemInfoCards",
        data: function() {
            return {
               propHeaders: [
                  { text: 'Name', value: 'name', width: '125' },
                  { text: 'Type', value: 'type' },
                  { text: 'Default', value: 'default', width: '100' },
                  { text: 'Description', value: 'description' },
               ],
               propItems: [
                  { name: 'type', type: 'string', default: 'undefined', description: 'Sets the type of the icon and the type of info and props displayed. For contacts, set "contact". If contact is a business, set "contact" here and then set "contact-is-business" prop to true to show the business icon. Any other string set here will be treated as a space type - refer to the design system "tokens.js" file for all possible space types that can be passed into this prop.' },
                  { name: 'contact-is-business', type: 'boolean', default: 'false', description: 'If set to true, this will show the correct business icon next to the title.' },
                  { name: 'title', type: 'string', default: 'undefined', description: 'Sets the title text.' },
                  { name: 'status', type: 'string', default: 'undefined', description: 'Manually set the status chip text. Color will be set automatically by the text string used.' },
                  { name: 'email', type: 'string', default: 'undefined', description: 'Manually set the email text displayed for "contact" type only.' },
                  { name: 'phone', type: 'string', default: 'undefined', description: 'Manually set the phone number text displayed for "contact" type only.' },
                  { name: 'contact-address', type: 'string', default: 'undefined', description: 'Manually set the address text displayed for "contact" type only.' },
                  { name: 'access-code', type: 'string', default: 'null', description: 'Manually set the access code pin that is displayed for "contact" type only.' },
                  { name: 'multiple-access-codes', type: 'boolean', default: 'false', description: 'If set to true, this will turn the access code text to say "Mulitple Access Codes" instead of the string passed to "access-code" prop.' },
                  { name: 'property-info', type: 'string', default: 'undefined', description: 'Manually set the property info text displayed for for "storage", "parking", and "residential" types only.' },
                  { name: 'category-one', type: 'string', default: 'undefined', description: 'Manually set category-one text for "storage", "parking", and "residential" types only.' },
                  { name: 'category-two', type: 'string', default: 'undefined', description: 'Manually set category-two text for "storage", "parking", and "residential" types only.' },
                  { name: 'amenities', type: 'string', default: 'undefined', description: 'Manually set amenities text for "storage", "parking", and "residential" types only.' }
               ],
               slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'button', description: 'This slot is where you can place a small sized HbBtn that shows up in top right corner of info card.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: 'Event for when the title is clicked on.' },
                ],
            }
        },
    }
</script>

<style scoped>

</style>